@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;900&display=swap);
html,
body {
  padding: 0;
  margin: 0;

  font-family: 'Mulish', sans-serif;
/*   font-family: 'Prompt', sans-serif; */
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.MuiInputBase-root.Mui-error {
  border: 1px solid #E05959;
}

.MuiSelect-root.Mui-error {
  border: 1px solid #E05959;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pagebreak {
	display: block;
	clear: both;
	page-break-after: always;
}

.MuiSelect-select:focus {
  background-color: #fff !important;
}

.marginProfit {
  margin-bottom: 16px; 
  max-height: 37px;
}
.profitPc div:nth-child(2) > div {
  margin-bottom: 33px !important; 
}

.tilesBoxShadow{
  -webkit-print-color-adjust: exact;
  -webkit-filter: blur(0);
}
